export const listCustomDocumentIds = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const receiveSearchDocuments = /* GraphQL */ `
  query customerIDCreatedAtIndex(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerIDCreatedAtIndex(
      customerID: $customerID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        appliedMapping
        btOrigin,
        companyCode
        companyName
        createdAt
        documentNumber
        documentSubType
        documentType
        hasBeenReProcessed
        id
        receiverEmailAddress
        secondaryStatus
        source
        status
        supplierCode
        supplierName
      }
      nextToken
    }
  }
`;

export const sendSearchDocuments = /* GraphQL */ `
  query customerIDCreatedAtIndex(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerIDCreatedAtIndex(
      customerID: $customerID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amountGross
        companyCode
        companyName
        createdAt
        currency
        documentNumber
        documentSubType
        documentType
        exportType
        hasBeenReProcessed
        id
        invoiceDate
        receiverCompanyCode
        receiverCompanyName
        secondaryStatus
        senderDocCode
        source
        status
      }
      nextToken
    }
  }
`;

export const receiveSearchDocumentNumber = /* GraphQL */ `
  query DocumentNumberCustomerIdIndex(
    $documentNumber: String!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentNumberCustomerIDIndex(
      documentNumber: $documentNumber
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        appliedMapping
        btOrigin,
        companyCode
        companyName
        createdAt
        documentNumber
        documentSubType
        documentType
        hasBeenReProcessed
        id
        ivsProcessID
        lastError
        receiverCompanyName
        receiverEmailAddress
        remark
        secondaryStatus
        source
        status
        supplierCode
        supplierName
      }
      nextToken
    }
  }
`;

export const sendSearchDocumentNumber = /* GraphQL */ `
  query DocumentNumberCustomerIdIndex(
    $documentNumber: String!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentNumberCustomerIDIndex(
      documentNumber: $documentNumber
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amountGross
        companyCode
        companyName
        createdAt
        currency
        documentNumber
        documentSubType
        documentType
        exportType
        hasBeenReProcessed
        id
        invoiceDate
        receiverCompanyCode
        receiverCompanyName
        secondaryStatus
        senderDocCode
        source
        status
      }
      nextToken
    }
  }
`;

export const receiveStatusDocuments = /* GraphQL */ `
  query statusCustomerIDIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusCustomerIDIndex(
      status: $status
      customerID: $customerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customerID
        appliedMapping
        companyCode
        companyName
        createdAt
        documentNumber
        documentSubType
        documentType
        id
        ivsProcessID
        lastError
        receiverCompanyName
        receiverEmailAddress
        remark
        secondaryStatus
        source
      }
      nextToken
    }
  }
`;

export const sendStatusDocuments = /* GraphQL */ `
  query statusCustomerIDIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusCustomerIDIndex(
      status: $status
      customerID: $customerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amountGross
        companyCode
        companyName
        createdAt
        currency
        documentNumber
        documentSubType
        documentType
        exportType
        hasBeenReProcessed
        id
        invoiceDate
        receiverCompanyCode
        receiverCompanyName
        secondaryStatus
        senderDocCode
        source
        status
        ivsProcessID
        appliedMapping
      }
      nextToken
    }
  }
`;

export const listReceivedMessagesByStatusIndex = /* GraphQL */ `
  query StatusCustomerIdReceivedIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusCustomerIDReceivedIndex(
      status: $status
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        customerID
        status
        secondaryStatus
        subject
        companyCode
        senderAddress
        domain
      }
      nextToken
    }
  }
`;

export const listReceivedMessagesByIndex = /* GraphQL */ `
  query customerIDCreatedAtReceivedIndex(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelReceivedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerIDCreatedAtReceivedIndex(
      customerID: $customerID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        customerID
        status
        secondaryStatus
        subject
        companyCode
        senderAddress
        domain
      }
      nextToken
    }
  }
`;