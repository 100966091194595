import React, { Component } from "react";
import { Slide, Paper, Button, Grid } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

//icons
import { SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon } from "@mui/icons-material";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="main">
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Paper>
              <div className="HeightContainer" style={{display: "flex"}}>
                <PerfectScrollbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                        <SentimentVeryDissatisfiedIcon style={{width: "30%"}}/>
                    </Grid>
                    <Grid item>
                      <br />

                      <h1>Something went wrong.</h1>

                      <br />

                        <details style={{ whiteSpace: "pre-wrap" }}>
                          {this.state.error && this.state.error.toString()}
                          <br />
                          {this.state.errorInfo.componentStack}
                        </details>

                      <br />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.setState({
                              error: null,
                              errorInfo: null,
                            })
                          }
                        >
                          Close error
                        </Button>

                      <br />
                    </Grid>
                  </Grid>
                </PerfectScrollbar>
              </div>
            </Paper>
          </Slide>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
