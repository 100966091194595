import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import {
  List,
  ListItem,
  Button,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

//recoil
import { useRecoilValue } from "recoil";
import {
  atomMainServicesArray,
  atomUserRoles,
  atomCurrentUser,
} from "../../Recoil";

//icons
import {
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

export default function NavBarMenu(props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const userRoles = useRecoilValue(atomUserRoles);
  const currentUser = useRecoilValue(atomCurrentUser);
  const mainServicesArray = useRecoilValue(atomMainServicesArray);

  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLogoutClick = (event) => {
    setLogoutAnchorEl(event.currentTarget);
  };

  const ListItemStyle = {
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.93em",
    textTransform: "uppercase",
    "&:not(.active)": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <List
      component="nav"
      aria-labelledby="main navigation"
      className={props.propsClass}
    >
      {(userRoles.includes(10000) ||
        userRoles.includes(11000) ||
        userRoles.includes(12000)) &&
      mainServicesArray.includes("Receive") ? (
        <ListItem
          to={"/Receive"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.Receive")}
        </ListItem>
      ) : null}

      {(userRoles.includes(50000) || userRoles.includes(51000)) &&
      mainServicesArray.includes("Send") ? (
        <ListItem
          to={"/Send"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.Send")}
        </ListItem>
      ) : null}

      {userRoles.includes(30000) &&
      mainServicesArray.includes("ProcurationManager") ? (
        <ListItem
          to={"/ProcurationManager"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.ProcurationManager")}
        </ListItem>
      ) : null}

      {userRoles.includes(20000) && mainServicesArray.includes("Report") ? (
        <ListItem
          to={"/Report"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.Report")}
        </ListItem>
      ) : null}

      {userRoles.includes(40000) && mainServicesArray.includes("FileUpload") ? (
        <ListItem
          to={"/FileUpload"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.FileUpload")}
        </ListItem>
      ) : null}

      {userRoles.includes(99000) ? (
        <ListItem
          to={"/Admin"}
          component={NavLink}
          sx={ListItemStyle}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.primary.main : null,
          })}
          button
        >
          {t("service.Admin")}
        </ListItem>
      ) : null}

      <ListItem>
        <Button
          onClick={handleLanguageClick}
          startIcon={
            <ReactCountryFlag
              style={{
                width: "1.8em",
                height: "0.85em",
              }}
              countryCode={i18n.language.toString().replace("-NL", "")}
              svg
            />
          }
          endIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          {i18n.language.replace("gb", "en").replace("-NL", "")}
        </Button>
        <Menu
          id="i18Menu"
          anchorEl={languageAnchorEl}
          keepMounted
          open={Boolean(languageAnchorEl)}
          onClose={() => setLanguageAnchorEl(null)}
          getcontentanchorel={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("nl");
              setLanguageAnchorEl(null);
            }}
          >
            <ReactCountryFlag
              className="CountryFlagListIcon"
              countryCode="nl"
              svg
            />
            Nederlands
          </MenuItem>
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("gb");
              setLanguageAnchorEl(null);
            }}
          >
            <ReactCountryFlag
              className="CountryFlagListIcon"
              countryCode="gb"
              svg
            />
            English
          </MenuItem>
        </Menu>
      </ListItem>

      <ListItem>
        <Button
          onClick={handleLogoutClick}
          startIcon={
            <AccountCircleIcon
              style={{
                width: "1.5em",
                height: "1em",
              }}
            />
          }
          endIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          {currentUser}
        </Button>
        <Menu
          id="i18Menu"
          anchorEl={logoutAnchorEl}
          keepMounted
          open={Boolean(logoutAnchorEl)}
          onClose={() => setLogoutAnchorEl(null)}
          getcontentanchorel={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={signOut}>
            <ExitToAppIcon /> {t("general.Signout")}
          </MenuItem>
        </Menu>
      </ListItem>
    </List>
  );
}
