import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import translationGb from "./locales/gb/translation.json";
import translationNl from "./locales/nl/translation.json";

i18n
  .use(detector)
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'cookie', 'navigator'],

      // keys or params to lookup language from
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    
      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: 'icreative-cloud.nl',

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' }
    },

    debug: false,
    fallbackLng: "gb", // use en if detected lng is not available
    keySeparator: '.',
    
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      gb: {
        translations: translationGb
      },
      nl: {
        translations: translationNl
      }
    },

    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;