import { v4 as uuid } from "uuid";

//AWS
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";

export const InsertCrudHistory = (
  customerID,
  change,
  section,
  tableLineID,
  name,
  currentUser,
  oldData,
  newData
) => {
  const GetChanges = (oldData, newData) => {
    let changes = [];
    let key = "";

    if (oldData === undefined) {
      oldData = {};
    }
    if (newData === undefined) {
      newData = {};
    }

    for (key in oldData) {
      if (oldData[key] == null) {
        oldData[key] = "";
      }
      if (newData[key] == null) {
        newData[key] = "";
      }

      if (
        newData[key] !== oldData[key] &&
        key !== "tableData" &&
        key !== "id" &&
        key !== "createdAt" &&
        key !== "updatedAt" &&
        key !== "customerID" &&
        key !== "owner"
      ) {
        let change = {};
        change["field"] = key;
        change["oldValue"] = oldData[key] === undefined ? "" : oldData[key];
        change["newValue"] = newData[key] === undefined ? "" : newData[key];

        changes.push(change);
      }
    }

    for (key in newData) {
      if (!(key in oldData) && key !== "tableData") {
        let change = {};
        change["field"] = key;
        change["oldValue"] = oldData[key] === undefined ? "" : oldData[key];
        change["newValue"] = newData[key] === undefined ? "" : newData[key];

        changes.push(change);
      }
    }

    for (const change of changes) {
      if (change.newValue === null) {
        change.newValue = "";
      }

      // You can also replace the old values if needed
      if (change.oldValue === null) {
        change.oldValue = "";
      }
    }

    return changes;
  };

  const changes = GetChanges(oldData, newData);
  if (changes && changes.length > 0) {
    API.graphql(
      graphqlOperation(mutations.createCrudHistory, {
        input: {
          id: uuid(),
          customerID: customerID,
          change: change,
          section: section,
          tableLineID: tableLineID,
          name: name,
          user: currentUser,
          changes: changes,
        },
      })
    );
  }
};

export const GetExceptionCount = async (
  customerID,
  exceptionCount,
  updateException,
  updateRM,
  updateActions
) => {
  let receive_invoiceExceptionCount = exceptionCount.receive_invoice;
  let receive_orderExceptionCount = exceptionCount.receive_order;
  let receive_orderResponseExceptionCount =
    exceptionCount.receive_orderresponse;
  let send_invoiceExceptionCount = exceptionCount.send_nvoice;
  let send_orderExceptionCount = exceptionCount.send_order;
  let send_orderresponseExceptionCount = exceptionCount.send_orderresponse;

  let receive_invoiceActionsCount = exceptionCount.receive_invoiceActions;
  let receive_orderActionsCount = exceptionCount.receive_orderActions;
  let receive_orderResponseActionsCount =
    exceptionCount.receive_orderresponseActions;
  let send_invoiceActionsCount = exceptionCount.send_invoiceActions;
  let send_orderActionsCount = exceptionCount.send_orderActions;
  let send_orderresponseActionsCount = exceptionCount.send_orderresponseActions;

  let RmExceptionCount = exceptionCount.receivedMessage;

  if (updateException) {
    let nextToken;
    var restultArray = [];
    do {
      const exceptionList = await API.graphql(
        graphqlOperation(customQueries.receiveStatusDocuments, {
          status: 99,
          customerID: { eq: customerID },
          limit: 500,
          nextToken: nextToken,
        })
      );
      nextToken = exceptionList.data.statusCustomerIDIndex.nextToken;
      restultArray.push(...exceptionList.data.statusCustomerIDIndex.items);
    } while (nextToken !== null && nextToken !== undefined);
    if (restultArray.length > 0) {
      receive_invoiceExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "invoice" &&
          exception.documentSubType === "receive"
      ).length;
      receive_orderExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "order" &&
          exception.documentSubType === "receive"
      ).length;
      receive_orderResponseExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "orderresponse" &&
          exception.documentSubType === "receive"
      ).length;
      send_invoiceExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "invoice" &&
          exception.documentSubType === "send"
      ).length;
      send_orderExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "order" &&
          exception.documentSubType === "send"
      ).length;
      send_orderresponseExceptionCount = restultArray.filter(
        (exception) =>
          exception.documentType === "orderresponse" &&
          exception.documentSubType === "send"
      ).length;
    } else {
      receive_invoiceExceptionCount = 0;
      receive_orderExceptionCount = 0;
      receive_orderResponseExceptionCount = 0;
      send_invoiceExceptionCount = 0;
      send_orderExceptionCount = 0;
      send_orderresponseExceptionCount = 0;
    }
  }

  if (updateActions) {
    let nextOnHoldToken;
    let restultOnHoldArray = [];
    let receive_invoiceOnholdCount = 0;
    let receive_orderOnholdCount = 0;
    let receive_orderresponseOnholdCount = 0;
    let send_invoiceOnholdCount = 0;
    let send_orderOnholdCount = 0;
    let send_orderresponseOnholdCount = 0;
    do {
      const onHoldList = await API.graphql(
        graphqlOperation(customQueries.receiveStatusDocuments, {
          status: 55,
          customerID: { eq: customerID },
          limit: 500,
          nextToken: nextOnHoldToken,
        })
      );
      nextOnHoldToken = onHoldList.data.statusCustomerIDIndex.nextToken;
      restultOnHoldArray.push(...onHoldList.data.statusCustomerIDIndex.items);
    } while (nextOnHoldToken !== null && nextOnHoldToken !== undefined);
    if (restultOnHoldArray.length > 0) {
      receive_invoiceOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "invoice" &&
          exception.documentSubType === "receive"
      ).length;
      receive_orderOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "order" &&
          exception.documentSubType === "receive"
      ).length;
      receive_orderresponseOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "orderresponse" &&
          exception.documentSubType === "receive"
      ).length;
      send_invoiceOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "invoice" &&
          exception.documentSubType === "send"
      ).length;
      send_orderOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "order" &&
          exception.documentSubType === "send"
      ).length;
      send_orderresponseOnholdCount = restultOnHoldArray.filter(
        (exception) =>
          exception.documentType === "orderresponse" &&
          exception.documentSubType === "send"
      ).length;
    }

    let send_invoiceActionsCountTemp = 0;
    let send_orderActionsCountTemp = 0;
    let send_orderresponseActionsCountTemp = 0;

    let nextActionToken;
    let restultActionArray = [];
    do {
      const actionList = await API.graphql(
        graphqlOperation(customQueries.receiveStatusDocuments, {
          status: 65,
          customerID: { eq: customerID },
          limit: 500,
          nextToken: nextActionToken,
        })
      );
      nextActionToken = actionList.data.statusCustomerIDIndex.nextToken;
      restultActionArray.push(...actionList.data.statusCustomerIDIndex.items);
    } while (nextActionToken !== null && nextActionToken !== undefined);
    if (restultActionArray.length > 0) {
      send_invoiceActionsCountTemp = restultActionArray.filter(
        (action) =>
          action.documentType === "invoice" && action.documentSubType === "send"
      ).length;
      send_orderActionsCountTemp = restultActionArray.filter(
        (action) =>
          action.documentType === "order" && action.documentSubType === "send"
      ).length;
      send_orderresponseActionsCountTemp = restultActionArray.filter(
        (action) =>
          action.documentType === "orderresponse" &&
          action.documentSubType === "send"
      ).length;
    }
    receive_invoiceActionsCount = receive_invoiceOnholdCount;
    receive_orderActionsCount = receive_orderOnholdCount;
    receive_orderResponseActionsCount = receive_orderresponseOnholdCount;
    send_invoiceActionsCount =
      send_invoiceOnholdCount + send_invoiceActionsCountTemp;
    send_orderActionsCount = send_orderOnholdCount + send_orderActionsCountTemp;
    send_orderresponseActionsCount =
      send_orderresponseOnholdCount + send_orderresponseActionsCountTemp;
  }

  if (updateRM) {
    let rmNextToken;
    let rmRestultArray = [];
    do {
      const rmExceptionList = await API.graphql(
        graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
          status: 99,
          customerID: { eq: customerID },
          limit: 500,
          nextToken: rmNextToken,
        })
      );
      rmNextToken =
        rmExceptionList.data.statusCustomerIDReceivedIndex.nextToken;
      rmRestultArray.push(
        ...rmExceptionList.data.statusCustomerIDReceivedIndex.items
      );
    } while (rmNextToken !== null && rmNextToken !== undefined);
    RmExceptionCount = rmRestultArray.length;
  }
  return {
    receive_invoice: receive_invoiceExceptionCount,
    receive_order: receive_orderExceptionCount,
    receive_orderresponse: receive_orderResponseExceptionCount,
    send_invoice: send_invoiceExceptionCount,
    send_order: send_orderExceptionCount,
    send_orderresponse: send_orderresponseExceptionCount,
    receive_invoiceActions: receive_invoiceActionsCount,
    receive_orderActions: receive_orderActionsCount,
    receive_orderresponseActions: receive_orderResponseActionsCount,
    send_invoiceActions: send_invoiceActionsCount,
    send_orderActions: send_orderActionsCount,
    send_orderresponseActions: send_orderresponseActionsCount,
    receivedMessage: RmExceptionCount,
  };
};

export const GetRMExceptionCount = async (customerID, exceptionCount) => {
  let nextToken;
  var restultArray = [];
  do {
    const rmExceptionList = await API.graphql(
      graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
        status: 99,
        customerID: { eq: customerID },
        limit: 500,
        nextToken: nextToken,
      })
    );
    nextToken = rmExceptionList.data.statusCustomerIDReceivedIndex.nextToken;
    restultArray.push(
      ...rmExceptionList.data.statusCustomerIDReceivedIndex.items
    );
  } while (nextToken !== null && nextToken !== undefined);
  const rmExceptionCount = restultArray.length;

  return { ...exceptionCount, receivedMessage: rmExceptionCount };
};
