import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Chip, useTheme, Grid } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

export function ReceiveChart(props) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  let labels = [
    t("general.Current") + " " + t("validationService.Exceptions"),
    t("general.Current") + " " + t("send.Actions"),
    t("validationService.Deleted") + " " + t("general.Today"),
    t("validationService.Returned") + " " + t("general.Today"),
    t("validationService.Processed") + " " + t("general.Today"),
  ];
  let dataSet = [
    props.exceptionCount,
    props.actionCount,
    props.deletedToday,
    props.returnedToday,
    props.processedToday,
  ];
  let colors = [
    theme.palette.secondary.main,
    "#08286a",
    "#ee506f",
    "#8770ff",
    theme.palette.primary.main,
  ];

  const data = labels.map((label, index) => ({
    id: index,
    value: dataSet[index],
    label: label,
    color: colors[index],
  }));

  const handleClick = (e) => {
    let expression = e.currentTarget.id.split("_")[0];
    switch (expression) {
      case "exception":
        navigate("/Receive/Invoice/ReceiveExceptions");
        break;
      case "action":
        navigate("/Receive/Invoice/ReceiveActions");
        break;
      case "returned":
        navigate("/Receive/Invoice/ReceiveSearch", {
          state: { status: "returnedTodayFromLanding" },
        });
        break;
      case "processed":
        navigate("/Receive/Invoice/ReceiveSearch", {
          state: { status: "processedTodayFromLanding" },
        });
        break;
      case "deleted":
        navigate("/Receive/Invoice/ReceiveSearch", {
          state: { status: "deletedTodayFromLanding" },
        });
        break;
      default:
        console.log("You clicked: " + expression);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={{ paddingRight: "50px" }}
    >
      <Grid item style={{ marginRight: "-30px" }}>
        <PieChart
          tooltip={{ trigger: "item" }}
          series={[
            {
              data: data,
              innerRadius: 50,
              outerRadius: 80,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: "gray",
              },
            },
          ]}
          width={270}
          height={200}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Grid>
      <Grid item className="ChipColumn">
        <div>
          <Chip
            id="exception_chip"
            label={props.exceptionCount}
            onClick={handleClick}
            color="secondary"
          />
          <Button id="exception_button" onClick={handleClick}>
            {t("general.Current") + " " + t("validationService.Exceptions")}
          </Button>
          <br />
          <Chip
            id="action_chip"
            label={props.actionCount}
            onClick={handleClick}
            style={{ backgroundColor: "#08286a", color: "white" }}
          />
          <Button id="action_button" onClick={handleClick}>
            {t("general.Current") + " " + t("send.Actions")}
          </Button>
          <br />
          <Chip
            id="deleted_chip"
            label={props.deletedToday}
            onClick={handleClick}
            style={{ backgroundColor: "#ee506f", color: "white" }}
          />
          <Button id="deleted_button" onClick={handleClick}>
            {t("validationService.Deleted") + " " + t("general.Today")}
          </Button>
          <br />
          <Chip
            id="returned_chip"
            label={props.returnedToday}
            onClick={handleClick}
            className="ReturnedChip"
          />
          <Button id="returned_button" onClick={handleClick}>
            {t("validationService.Returned") + " " + t("general.Today")}
          </Button>
          <br />
          <Chip
            id="processed_chip"
            label={props.processedToday}
            onClick={handleClick}
            color="primary"
          />
          <Button id="processed_button" onClick={handleClick}>
            {t("validationService.Processed") + " " + t("general.Today")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export function SendChart(props) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  let labels = [
    t("general.Current") + " " + t("validationService.Exceptions"),
    t("general.Current") + " " + t("send.Actions"),
    t("validationService.Deleted") + " " + t("general.Today"),
    t("validationService.Processed") + " " + t("general.Today"),
  ];

  let colors = [
    theme.palette.secondary.main,
    "#08286a",
    "#ee506f",
    "#8770ff",
    theme.palette.primary.main,
  ];

  let dataSet = [
    props.exceptionCount,
    props.actionCount,
    props.deletedToday,
    props.returnedToday,
    props.processedToday,
  ];

  const data = labels.map((label, index) => ({
    id: index,
    value: dataSet[index],
    label: label,
    color: colors[index],
  }));

  const handleClick = (e) => {
    let expression = e.currentTarget.id.split("_")[0];
    switch (expression) {
      case "exception":
        navigate("/Send/Invoice/SendExceptions");
        break;
      case "action":
        navigate("/Send/Invoice/SendActions");
        break;
      case "returned":
        navigate("/Send/Invoice/SendSearch", {
          state: { status: "returnedTodayFromLanding" },
        });
        break;
      case "processed":
        navigate("/Send/Invoice/SendSearch", {
          state: { status: "processedTodayFromLanding" },
        });
        break;
      case "deleted":
        navigate("/Send/Invoice/SendSearch", {
          state: { status: "deletedTodayFromLanding" },
        });
        break;
      default:
        console.log("You clicked: " + expression);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item style={{ marginRight: "-30px" }}>
        <PieChart
          tooltip={{ trigger: "item" }}
          series={[
            {
              data: data,
              innerRadius: 50,
              outerRadius: 80,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: "gray",
              },
            },
          ]}
          width={270}
          height={200}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Grid>
      <Grid item className="ChipColumn">
        <div>
          <Chip
            id="exception_chip"
            label={props.exceptionCount}
            onClick={handleClick}
            color="secondary"
          />
          <Button id="exception_button" onClick={handleClick}>
            {t("general.Current") + " " + t("validationService.Exceptions")}
          </Button>
          <br />
          <Chip
            id="action_chip"
            label={props.actionCount}
            onClick={handleClick}
            style={{ backgroundColor: "#08286a", color: "white" }}
          />
          <Button id="action_button" onClick={handleClick}>
            {t("general.Current") + " " + t("send.Actions")}
          </Button>
          <br />
          <Chip
            id="deleted_chip"
            label={props.deletedToday}
            onClick={handleClick}
            style={{ backgroundColor: "#ee506f", color: "white" }}
          />
          <Button id="deleted_button" onClick={handleClick}>
            {t("validationService.Deleted") + " " + t("general.Today")}
          </Button>
          <br />
          <Chip
            id="processed_chip"
            label={props.processedToday}
            onClick={handleClick}
            color="primary"
          />
          <Button id="processed_button" onClick={handleClick}>
            {t("validationService.Processed") + " " + t("general.Today")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export function ReceivedMessageChart(props) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  let labels = [
    t("receivedMessage.ProcessingError"),
    t("receivedMessage.Rejected"),
    t("receivedMessage.Excluded"),
    t("receivedMessage.NoAttachment"),
    t("validationService.Processed") + " " + t("general.Today"),
  ];

  let colors = [
    theme.palette.secondary.main,
    "#8770ff",
    "#ee506f",
    "#08286a",
    theme.palette.primary.main,
  ];

  let dataSet = [
    props.exceptionCount,
    props.rejectedCount,
    props.excludedCount,
    props.noAttachmentCount,
    props.processedToday,
  ];

  const data = labels.map((label, index) => ({
    id: index,
    value: dataSet[index],
    label: label,
    color: colors[index],
  }));

  const handleClick = (e) => {
    let expression = e.currentTarget.id.split("_")[0];
    switch (expression) {
      case "exception":
        navigate("/Receive/Invoice/ReceivedMessages");
        break;
      case "processed":
        navigate("/Receive/Invoice/ReceivedMessages", {
          state: { status: "processedTodayFromLanding" },
        });
        break;
      case "rejected":
        navigate("/Receive/Invoice/ReceivedMessages", {
          state: { status: "rejectedFromLanding" },
        });
        break;
      case "excluded":
        navigate("/Receive/Invoice/ReceivedMessages", {
          state: { status: "excludedFromLanding" },
        });
        break;
      case "noAttachment":
        navigate("/Receive/Invoice/ReceivedMessages", {
          state: { status: "noAttachmentFromLanding" },
        });
        break;
      default:
        console.log("You clicked: " + expression);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={{ paddingRight: "50px" }}
    >
      <Grid item style={{ marginRight: "-30px" }}>
        <PieChart
          tooltip={{ trigger: "item" }}
          series={[
            {
              data: data,
              innerRadius: 50,
              outerRadius: 80,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: "gray",
              },
            },
          ]}
          width={270}
          height={200}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Grid>
      <Grid item className="ChipColumn">
        <div>
          <Chip
            id="exception_chip"
            label={props.exceptionCount}
            onClick={handleClick}
            color="secondary"
          />
          <Button id="exception_button" onClick={handleClick}>
            {t("receivedMessage.ProcessingError")}
          </Button>
          <br />
          <Chip
            id="rejected_chip"
            label={props.rejectedCount}
            onClick={handleClick}
            style={{ backgroundColor: "#8770ff", color: "white" }}
          />
          <Button id="rejected_button" onClick={handleClick}>
            {t("receivedMessage.Rejected")}
          </Button>
          <br />
          <Chip
            id="excluded_chip"
            label={props.excludedCount}
            onClick={handleClick}
            style={{ backgroundColor: "#ee506f", color: "white" }}
          />
          <Button id="excluded_button" onClick={handleClick}>
            {t("receivedMessage.Excluded")}
          </Button>
          <br />
          <Chip
            id="noAttachment_chip"
            label={props.noAttachmentCount}
            onClick={handleClick}
            style={{ backgroundColor: "#08286a", color: "white" }}
          />
          <Button id="noAttachment_button" onClick={handleClick}>
            {t("receivedMessage.NoAttachment")}
          </Button>
          <br />
          <Chip
            id="processed_chip"
            label={props.processedToday}
            onClick={handleClick}
            color="primary"
          />
          <Button id="processed_button" onClick={handleClick}>
            {t("validationService.Processed") + " " + t("general.Today")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
