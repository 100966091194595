import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialTable from "@material-table/core";

//AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";

//components
import { TableIcons, TableLocal } from "../Components/TableComponents";

export default function AdminChart() {
  const [data, setData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [receiveExceptionData, setReceiveExceptionData] = useState([]);
  const [sendExceptionData, setSendExceptionData] = useState([]);
  const [rmExceptionData, setRmExceptionData] = useState([]);
  const [rmIsLoading, setRmIsLoading] = useState(false);
  const [ivsIsLoading, setIvsIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    GetCustomers();
    GetExceptions();
    GetRmExceptions();
  }, []);

  const GetCustomers = async () => {
    try {
      let customersNextToken
      var customersRestultArray = [];
      do {
        const customerList = await API.graphql(
          graphqlOperation(queries.listCustomers, {
            limit: 500,
            nextToken: customersNextToken,
          })
        );
        customersNextToken = customerList.data.listCustomers.nextToken;
        customersRestultArray.push(...customerList.data.listCustomers.items)
      } while (customersNextToken !== null && customersNextToken !== undefined);
    } catch (err) {
      console.log(err);
    }
    setCustomers(customersRestultArray);
  };

  const GetExceptions = async () => {
    setIvsIsLoading(true);
    try {
      let nextToken
      var restultArray = [];
      do {
        const exceptionList = await API.graphql(
          graphqlOperation(customQueries.receiveStatusDocuments, {
            status: 99,
            limit: 500,
            nextToken: nextToken,
          })
        );
        nextToken = exceptionList.data.statusCustomerIDIndex.nextToken;
        restultArray.push(...exceptionList.data.statusCustomerIDIndex.items)
      } while (nextToken !== null && nextToken !== undefined);
    } catch (err) {
      console.log(err);
    }

    try {
      let nextOnholdToken
      do {
        const onHoldList = await API.graphql(
          graphqlOperation(customQueries.receiveStatusDocuments, {
            status: 55,
            limit: 500,
            nextToken: nextOnholdToken,
          })
        );
        nextOnholdToken = onHoldList.data.statusCustomerIDIndex.nextToken;
        restultArray.push(...onHoldList.data.statusCustomerIDIndex.items)
      } while (nextOnholdToken !== null && nextOnholdToken !== undefined);
    } catch (err) {
      console.log(err);
    }

    let receiveExceptions = restultArray.filter(row => row.documentSubType === 'receive')
    let sendExceptions = restultArray.filter(row => row.documentSubType === 'send')

    setReceiveExceptionData(receiveExceptions);
    setSendExceptionData(sendExceptions);
    setIvsIsLoading(false);
  };

  const GetRmExceptions = async () => {
    setRmIsLoading(true);
    try {
      let nextTokenRm
      var restultArrayRm = [];
      do {
        const exceptionListRm = await API.graphql(
          graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
            status: 99,
            limit: 500,
            nextToken: nextTokenRm,
          })
        );
        nextTokenRm =
          exceptionListRm.data.statusCustomerIDReceivedIndex.nextToken;
        restultArrayRm.push(...exceptionListRm.data.statusCustomerIDReceivedIndex.items)
      } while (nextTokenRm !== null && nextTokenRm !== undefined);
    } catch (err) {
      console.log(err);
    }
    setRmExceptionData(restultArrayRm);
    setRmIsLoading(false);
  };

  const summarizeArray = (array) => {
    return array.reduce((acc, item) => {
      if (acc[item.customerID]) {
        acc[item.customerID]++;
      } else {
        acc[item.customerID] = 1;
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    // Summarize each array
    const receiveSummary = summarizeArray(receiveExceptionData);
    const sendSummary = summarizeArray(sendExceptionData);
    const rmSummary = summarizeArray(rmExceptionData);

    // Combine summaries
    const combinedSummary = {};
    const mergeSummary = (summary, key) => {
      for (let customerID in summary) {
        if (!combinedSummary[customerID]) {
          combinedSummary[customerID] = { customerID, sendCount: 0, receiveCount: 0, rmCount: 0 };
        }
        combinedSummary[customerID][key] = summary[customerID];
      }
    };

    mergeSummary(receiveSummary, 'receiveExceptions');
    mergeSummary(sendSummary, 'sendExceptions');
    mergeSummary(rmSummary, 'rmExceptions');

    // Filter out customer IDs with all counts as zero
    const finalSummary = Object.values(combinedSummary).filter(customer => 
      customer.sendExceptions > 0 || customer.receiveExceptions > 0 || customer.rmExceptions > 0
    );

    // Create a map of customer IDs to customer names
    const customerMap = customers.reduce((acc, customer) => {
      // Append '*' to customer.name if selfValidation is true
      const nameWithValidation = customer.selfValidation ? `${customer.name}*` : customer.name;

      // Add the customer to the accumulator object
      acc[customer.id] = nameWithValidation;

      return acc;
    }, {});

    // Add customerName to the final summary
    finalSummary.forEach(customer => {
      customer.customerName = customerMap[customer.customerID] || 'Unknown';
    });
    setData(finalSummary);
  }, [receiveExceptionData, sendExceptionData, rmExceptionData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <MaterialTable
        title={t("landing.ExceptionsOverview")}
        icons={TableIcons}
        isLoading={rmIsLoading || ivsIsLoading}
        columns={[
          {
            title: t("general.Customer"),
            field: "customerID",
            hidden: true,
          },
          {
            title: t("general.Customer"),
            field: "customerName",
          },
          {
            title: t("service.Receive"),
            field: "receiveExceptions",
            emptyValue: 0,
            align: "right",
            defaultSort: "desc",
          },
          {
            title: t("service.ReceivedMessages"),
            field: "rmExceptions",
            emptyValue: 0,
            align: "right",
          },
          {
            title: t("service.Send"),
            field: "sendExceptions",
            emptyValue: 0,
            align: "right",
          },
        ]}
        data={data}
        options={{
          exportButton: false,
          draggable: false,
          pageSizeOptions: [],
          paging: false,
          padding: "dense",
          exportAllData: false,
          search: false,
          toolbar: true,
        }}
        localization={TableLocal(t)}
      />
  );
}
