import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { List, ListItem, useTheme } from "@mui/material";

//recoil
import { useSetRecoilState, useRecoilValue } from "recoil";
import { atomDocumentType, atomReports } from "../../Recoil";

//components
import { CustomerSelector } from "./StyleComponents";

export default function NavBarSecondaryMenu(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const setDocumentType = useSetRecoilState(atomDocumentType);
  const allReports = useRecoilValue(atomReports);

  const ListItemStyle = {
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.93em",
    textTransform: "uppercase",
    "&:not(.active)": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <List
      component="nav"
      aria-labelledby="main navigation"
      className={props.propsClass}
    >
      {location.pathname.startsWith("/Receive") ? (
        <React.Fragment>
          <ListItem
            to={"/Receive/Invoice"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("Invoice")}
          >
            {t("validationService.Invoices")}
          </ListItem>

          <ListItem
            to={"/Receive/Order"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("Order")}
          >
            {t("validationService.Orders")}
          </ListItem>

          <ListItem
            to={"/Receive/OrderResponse"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("OrderResponse")}
          >
            {t("validationService.OrderResponse")}
          </ListItem>
        </React.Fragment>
      ) : null}

      {location.pathname.startsWith("/Send") ? (
        <React.Fragment>
          <ListItem
            to={"/Send/Invoice"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("Invoice")}
          >
            {t("validationService.Invoices")}
          </ListItem>

          <ListItem
            to={"/Send/Order"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("Order")}
          >
            {t("validationService.Orders")}
          </ListItem>

          <ListItem
            to={"/Send/OrderResponse"}
            component={NavLink}
            sx={ListItemStyle}
            style={({ isActive }) => ({
              backgroundColor: isActive ? theme.palette.primary.main : null,
            })}
            button
            onClick={() => setDocumentType("OrderResponse")}
          >
            {t("validationService.OrderResponse")}
          </ListItem>
        </React.Fragment>
      ) : null}

      {(() => {
        if (location.pathname.startsWith("/Report")) {
          if (Array.isArray(allReports) && allReports.length > 0) {
            return allReports.map((report) => (
              <ListItem
                key={report.name}
                to={"/Report/" + report.name.replace(/ /g, "_")}
                component={NavLink}
                sx={ListItemStyle}
                style={({ isActive }) => ({
                  backgroundColor: isActive ? theme.palette.primary.main : null,
                })}
                button
              >
                {report.name}
              </ListItem>
            ));
          }
        }
        return null;
      })()}

      <div
        style={{
          position: "absolute",
          right: "10px",
        }}
      >
        <CustomerSelector />
      </div>
    </List>
  );
}
