import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledEngineProvider,
  Grow,
  Paper,
  Backdrop,
  Snackbar,
  Alert,
  AlertTitle,
  Slide,
  Button,
} from "@mui/material";
import axios from "axios";
//
import { createTheme, ThemeProvider } from "@mui/material/styles";

//AWS
import {
  Authenticator,
  Heading,
  useTheme,
  AmplifyProvider,
  useAuthenticator,
  TextField as AmplifyTextField,
  Button as AmplifyButton,
  Card as AmplifyCard,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Hub } from "aws-amplify";
import awsconfig from "./aws-exports";

//recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atomBackDropIsOpen, atomCurrentUser, atomCustomerID } from "./Recoil";

import * as data from "../package.json";

//components
import { Context } from "./envVars";
import { LoadingSpinner } from "./pages/Components/StyleComponents";

//pages
import MainApp from "./MainApp";

export default function App() {
  const { t } = useTranslation();
  const { tokens } = useTheme();
  const backDropIsOpen = useRecoilValue(atomBackDropIsOpen);
  const [ssoIsLoading, setSsoIsLoading] = useState(false);
  const setCurrentUser = useSetRecoilState(atomCurrentUser);
  const setCustomerID = useSetRecoilState(atomCustomerID);
  const versionData = JSON.parse(JSON.stringify(data));
  const currentDate = new Date();
  const [versionSnackbarIsOpen, setVersionSnackbarIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);
  const { signOut } = useAuthenticator((context) => [context.user]);

  const listener = (data) => {
    if (data.payload.event === "verify") {
      window.location.reload();
    }
  };

  Hub.listen("auth", listener);

  useEffect(() => {
    if (window.location.href.endsWith("icreative-cloud.nl/formslogin")) {
      setShowAuthenticator(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "authenticated") {
      if(user === undefined){
        window.location.reload();
      }
      if (user.signInUserSession !== undefined) {
        let localUser = user.signInUserSession.idToken.payload;
        setCurrentUser(localUser.name);
        if(localUser["cognito:groups"][0].startsWith("eu-west-1")){
          setCustomerID(localUser["cognito:groups"][1]);
        } else {
          setCustomerID(localUser["cognito:groups"][0]);
        }

        fetch(process.env.PUBLIC_URL + "/version.txt")
          .then((res) => res.text())
          .then((text) => {
            if (versionData.version.trim() !== text.trim()) {
              setVersionSnackbarIsOpen(true);
            }
          });
      } else {
        signOut();
      }
    }
  }, [authStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = createTheme({
    palette: {
      primary: {
        main: "#629afd",
        contrastText: "#fff",
      },
      secondary: {
        main: "#f38363",
        contrastText: "#fff",
      },
      darkBackground: {
        main: "#010933",
      },
      greyBox: {
        main: "#f7f7f7",
      },
      text: {
        primary: "#445262",
      },
    },
    typography: {
      fontFamily: "Open sans",
      fontWeightRegular: 400,
    },
    shape: {
      borderRadius: 0,
    },
  });

  theme.overrides = {
    ...theme.overrides,
    MuiTabs: {
      ...theme.MuiTabs,
      indicator: {
        height: "6px",
      },
    },
    MuiTab: {
      ...theme.MuiTab,
      root: {
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiListItem: {
      root: {
        a: {
          "&.active": {
            span: {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        return (
          <Heading
            level={5}
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          >
            Sign in to your account
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        return <Heading level={5}>Multi-factor authentication</Heading>;
      },
    },
    SetupTOTP: {
      Header() {
        return <Heading level={5}>Setup multi-factor authentication</Heading>;
      },
    },
  };

  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: Context["unit"] + " - " + Context["cleanEnv"],
        totpUsername: email,
      },
    },
  };

  const amplifyTheme = {
    name: "Auth Theme",
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: { value: theme.palette.primary.main },
          },
        },
      },
    },
  };

  const TransitionLeft = (props) => {
    return <Slide {...props} direction="left" unmountOnExit />;
  };

  const Refresh = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const backgroundPath = () => {
    switch (Context["unit"]) {
      case "ICreative":
        return require("./images/4CEE_Background.jpg");
      case "Easy Systems":
        return require("./images/Easy_Background.jpg");
      default:
        return require("./images/4CEE_Background.jpg");
    }
  };

  const handleAuthDetermination = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSsoIsLoading(true);

    let identifier = email.split("@")[1];
    axios
      .post(
        Context["baseUrl"] + "/ivs/sso",
        {
          identifier: identifier,
        },
        {
          headers: {
            "x-api-key": Context["apikey"],
            customerid: Context["unit"].toLowerCase(),
          },
        }
      )
      .then((res) => {
        if (res.data.sso) {
          let redirectUrl =
            "https://" +
            Context["ssoUrl"] +
            "/authorize?response_type=" +
            awsconfig.oauth.responseType +
            "&client_id=" +
            awsconfig.aws_user_pools_web_client_id +
            "&idp_identifier=" +
            identifier +
            "&redirect_uri=" +
            Context["appUrl"];
          window.location.replace(redirectUrl);
        } else {
          ShowAuthenticator();
        }
      })
      .catch((err) => {
        console.log(err);
        setSsoIsLoading(false);
        ShowAuthenticator();
      });
  };

  const ShowAuthenticator = () => {
    setShowAuthenticator(true);
    setTimeout(() => {
      document.getElementsByName("username")[0].value = email;
    }, 100);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Backdrop className="BackDrop" open={backDropIsOpen}>
          <div style={{ width: "10%" }}>
            <LoadingSpinner />
          </div>
        </Backdrop>
        {authStatus !== "authenticated" ? (
          <div
            style={{
              backgroundImage: `url(${backgroundPath()})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grow in={true} timeout={1500}>
              <Paper elevation={4} className="LogInPaper">
                <AmplifyProvider theme={amplifyTheme}>
                  {!showAuthenticator ? (
                    <AmplifyCard variation="outlined">
                      <form
                        onSubmit={(e) => handleAuthDetermination(e)}
                        style={{
                          padding: "0px 20px 20px 20px",
                          width: "478px",
                        }}
                      >
                        <div
                          style={{ marginLeft: "-36px", paddingBottom: "30px" }}
                        >
                          {components.SignIn.Header()}
                        </div>
                        <AmplifyTextField
                          id="username"
                          type="email"
                          label="Email"
                          autoComplete="username"
                          isRequired={true}
                          placeholder="Enter you Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ marginBottom: "20px" }}
                        />
                        <AmplifyButton
                          isLoading={ssoIsLoading}
                          variation="primary"
                          isFullWidth={true}
                          type="submit"
                        >
                          Sign in
                        </AmplifyButton>
                      </form>
                    </AmplifyCard>
                  ) : (
                    <Authenticator
                      hideSignUp={true}
                      formFields={formFields}
                      loginMechanisms={["email"]}
                      components={components}
                    />
                  )}
                </AmplifyProvider>
              </Paper>
            </Grow>
          </div>
        ) : (
          <React.Fragment>
            <MainApp />
            <Snackbar
              open={versionSnackbarIsOpen}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              TransitionComponent={TransitionLeft}
              onClose={() => setVersionSnackbarIsOpen(false)}
              sx={{ backgroundColor: "white" }}
            >
              <Alert
                severity="info"
                variant="outlined"
                onClose={() => setVersionSnackbarIsOpen(false)}
              >
                <AlertTitle>{t("general.NewVersion")}</AlertTitle>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => Refresh()}
                >
                  {t("general.VersionRefresh")}
                </Button>
              </Alert>
            </Snackbar>
          </React.Fragment>
        )}

        <div
          className="footer"
          style={{ backgroundColor: theme.palette.darkBackground.main }}
        >
          {currentDate.getFullYear()} &copy; 4CEE | version:{" "}
          {versionData.version}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}