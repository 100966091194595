import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  IconButton,
  ButtonBase,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  Grow,
  Paper,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactMarkdown from "react-markdown";

//recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  atomCustomerID,
  atomMainServicesArray,
  atomCurrentUser,
  atomBackDropIsOpen,
  atomSnackbarIsOpen,
  atomSnackbarSeverity,
  atomSnackbarMessage,
  atomUserRoles,
} from "../Recoil";

//AWS
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as customQueries from "../graphql/customQueries";

//files
import ChangeLog from "../CHANGELOG.md";

//components
import { Context } from "../envVars";
import { GetExceptionCount } from "./Components/Functions";
import { FileIcon, defaultStyles } from "./Components/FileIcons";

//icons
import {
  OpenInNew as OpenInNewIcon,
  ManageAccounts as AdministrationIcon,
  Inbox as ReceiveIcon,
  Send as SendIcon,
  Assessment as ReportIcon,
  HowToReg as ProcIcon,
  Info as InfoIcon,
  StickyNote2 as ReleaseNotesIcon,
  CloudUpload as FileUploadIcon,
} from "@mui/icons-material";

//pages
import {
  ReceiveChart,
  ReceivedMessageChart,
  SendChart,
} from "./Landing/ValidationChart";
import AdminChart from "./Landing/AdminChart";

export default function Landing() {
  const { t } = useTranslation();
  const theme = useTheme();
  const backDropIsOpen = useRecoilValue(atomBackDropIsOpen);
  const [markDown, setMarkDown] = useState("");
  const [whatsNewModalIsOpen, setWhatsNewModalIsOpen] = useState(false);
  const [releasenotesModalIsOpen, setReleasenotesModalIsOpen] = useState(false);
  const [releasenotesIsLoading, setReleasenotesIsLoading] = useState(true);
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [exceptionCount, setExceptionCount] = useState(0);
  const [rmRejectedCount, setRmRejectedCount] = useState(0);
  const [rmExcludedCount, setRmExcludedCount] = useState(0);
  const [rmNoAttachmentCount, setRmNoAttachmentCount] = useState(0);
  const [processedTodayCount, setProcessedTodayCount] = useState(0);
  const [rmProcessedTodayCount, setRmProcessedTodayCount] = useState(0);
  const [returnedTodayCount, setReturnedTodayCount] = useState(0);
  const [deletedToday, setDeletedToday] = useState(0);
  const customerID = useRecoilValue(atomCustomerID);
  const mainServicesArray = useRecoilValue(atomMainServicesArray);
  const userRoles = useRecoilValue(atomUserRoles);
  const currentUser = useRecoilValue(atomCurrentUser);

  const setSnackbarIsOpen = useSetRecoilState(atomSnackbarIsOpen);
  const setSnackbarSeverity = useSetRecoilState(atomSnackbarSeverity);
  const setSnackbarMessage = useSetRecoilState(atomSnackbarMessage);

  useEffect(() => {
    setReleasenotesIsLoading(true);
    if (Context.environment !== "") {
      Storage.configure({
        AWSS3: {
          bucket: "appreleasenotes",
          region: "eu-west-1",
        },
      });

      Storage.list(Context.environment + "/", {
        customPrefix: {
          public: "",
        },
      })
        .then((result) => {
          let slicedList = result.results
            .filter((releaseNote) => releaseNote.size > 0)
            .reverse();
          setReleaseNotes(slicedList);
          setTimeout(() => {
            setReleasenotesIsLoading(false);
          }, 200);
        })
        .catch((err) => console.log(err));
    }
    fetch(ChangeLog)
      .then((res) => res.text())
      .then((text) => setMarkDown(text));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerID !== "" && customerID !== "admin") {
      getCounts();
    }
  }, [customerID]); // eslint-disable-line react-hooks/exhaustive-deps

  const OpenReleaseNotes = (s3Resource) => {
    Storage.get(s3Resource, {
      customPrefix: {
        public: "",
      },
    })
      .then((res) => {
        window.open(res, "_blank");
      })
      .catch((err) => {
        console.log(err);
        setSnackbarSeverity("error");
        setSnackbarMessage(t("errorHandler.ErrorGettingFile") + err);
        setSnackbarIsOpen(true);
      });
  };

  const getCounts = async () => {
    const today = new Date();
    today.setHours(0, 0, 0);

    GetExceptionCount(customerID, exceptionCount, true, true, true).then(
      (count) => {
        setExceptionCount(count);
      }
    );

    try {
      let nextToken;
      let restultArray = [];

      do {
        const processedList = await API.graphql(
          graphqlOperation(customQueries.receiveSearchDocuments, {
            customerID: customerID,
            createdAt: { ge: today },
            filter: {
              and: {
                status: {
                  eq: 3,
                },
              },
            },
            limit: 500,
            nextToken: nextToken,
          })
        );
        nextToken = processedList.data.customerIDCreatedAtIndex.nextToken;
        restultArray.push(...processedList.data.customerIDCreatedAtIndex.items);
      } while (nextToken !== null && nextToken !== undefined);

      let receiveCount = restultArray.filter(
        (item) => item.documentSubType === "receive"
      ).length;
      let sendCount = restultArray.filter(
        (item) => item.documentSubType === "send"
      ).length;

      setProcessedTodayCount({ receive: receiveCount, send: sendCount });
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      let rmNextToken
      let rmRestultArray = [];
      do {
        const rmProcessedList = await API.graphql(
          graphqlOperation(customQueries.listReceivedMessagesByIndex, {
            customerID: customerID,
            createdAt: { ge: today },
            filter: {
              and: {
                status: {
                  eq: 3,
                },
              },
            },
            limit: 500,
            nextToken: rmNextToken,
          })
        );
        rmNextToken =
          rmProcessedList.data.customerIDCreatedAtReceivedIndex.nextToken;
        rmRestultArray.push(
          ...rmProcessedList.data.customerIDCreatedAtReceivedIndex.items
        );
      } while (rmNextToken !== null && rmNextToken !== undefined);
      setRmProcessedTodayCount(rmRestultArray.length);
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      let rmRejectedNextToken
      let rmRejectedRestultArray = [];
      do {
        const rmRejectedList = await API.graphql(
          graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
            status: 1,
            customerID: { eq: customerID },
            limit: 500,
            nextToken: rmRejectedNextToken,
          })
        );
        rmRejectedNextToken =
          rmRejectedList.data.statusCustomerIDReceivedIndex.nextToken;
        rmRejectedRestultArray.push(
          ...rmRejectedList.data.statusCustomerIDReceivedIndex.items
        );
      } while (
        rmRejectedNextToken !== null &&
        rmRejectedNextToken !== undefined
      );
      setRmRejectedCount(rmRejectedRestultArray.length);
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      let rmExcludedNextToken
      let rmExcludedRestultArray = [];

      if (customerID !== "") {
        do {
          const rmExcludedList = await API.graphql(
            graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
              status: 12,
              customerID: { eq: customerID },
              limit: 500,
              nextToken: rmExcludedNextToken,
            })
          );
          rmExcludedNextToken =
            rmExcludedList.data.statusCustomerIDReceivedIndex.nextToken;
          rmExcludedRestultArray.push(
            ...rmExcludedList.data.statusCustomerIDReceivedIndex.items
          );
        } while (
          rmExcludedNextToken !== null &&
          rmExcludedNextToken !== undefined
        );
        setRmExcludedCount(rmExcludedRestultArray.length);
      } else {
        setRmExcludedCount(0);
      }
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      let rmNoAttachmentNextToken
      let rmNoAttachmentRestultArray = [];

      do {
        const rmNoAttachmentList = await API.graphql(
          graphqlOperation(customQueries.listReceivedMessagesByStatusIndex, {
            status: 2,
            customerID: { eq: customerID },
            limit: 500,
            nextToken: rmNoAttachmentNextToken,
          })
        );
        rmNoAttachmentNextToken =
          rmNoAttachmentList.data.statusCustomerIDReceivedIndex.nextToken;
        rmNoAttachmentRestultArray.push(
          ...rmNoAttachmentList.data.statusCustomerIDReceivedIndex.items
        );
      } while (
        rmNoAttachmentNextToken !== null &&
        rmNoAttachmentNextToken !== undefined
      );
      setRmNoAttachmentCount(rmNoAttachmentRestultArray.length);
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      const returnedList = await API.graphql(
        graphqlOperation(customQueries.receiveStatusDocuments, {
          status: 2,
          customerID: { eq: customerID },
          filter: {
            and: {
              createdAt: {
                ge: today,
              },
            },
          },
          limit: 500,
        })
      );
      setReturnedTodayCount(
        returnedList.data.statusCustomerIDIndex.items.length
      );
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }

    try {
      let deleteRestultArray = [];
      let nextDeleteToken = null;
      do {
        const deletedList = await API.graphql(
          graphqlOperation(customQueries.receiveStatusDocuments, {
            status: 35,
            customerID: { eq: customerID },
            filter: {
              and: {
                createdAt: {
                  ge: today,
                },
              },
            },
            limit: 500,
            nextToken: nextDeleteToken,
          })
        );
        nextDeleteToken = deletedList.data.statusCustomerIDIndex.nextToken;
        deleteRestultArray.push(...deletedList.data.statusCustomerIDIndex.items);
      } while (nextDeleteToken !== null && nextDeleteToken !== undefined);

      let receiveCount = deleteRestultArray.filter(
        (item) => item.documentSubType === "receive"
      ).length;
      let sendCount = deleteRestultArray.filter(
        (item) => item.documentSubType === "send"
      ).length;
      setDeletedToday({ receive: receiveCount, send: sendCount });
    } catch (err) {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        t("errorHandler.ErrorRetrieving") + err.errors[0].message
      );
      setSnackbarIsOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        className="LandingModal"
        open={whatsNewModalIsOpen}
        onClose={() => setWhatsNewModalIsOpen(false)}
      >
        <DialogTitle>{t("landing.WhatsNew")}</DialogTitle>
        <DialogContent>
          <PerfectScrollbar>
            <ReactMarkdown className="ChangeLog" children={markDown} />
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setWhatsNewModalIsOpen(false)}
          >
            {t("general.Close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="LandingModal"
        open={releasenotesModalIsOpen}
        onClose={() => setReleasenotesModalIsOpen(false)}
      >
        <DialogTitle>Release notes</DialogTitle>
        <DialogContent>
          <PerfectScrollbar>
            {!releasenotesIsLoading ? (
              <List>
                {releaseNotes.map((releaseNote) => {
                  const fileName = releaseNote.key.split("/").pop();
                  const ext = releaseNote.key.split(".").pop();
                  return (
                    <ListItem
                      key={fileName}
                      button
                      onClick={() => OpenReleaseNotes(releaseNote.key)}
                    >
                      <ListItemIcon className="FileIcon">
                        <FileIcon extension={ext} {...defaultStyles[ext]} />
                      </ListItemIcon>
                      <ListItemText primary={fileName} />
                      <Tooltip title="Click to open">
                        <IconButton
                          onClick={() => OpenReleaseNotes(releaseNote.key)}
                          size="large"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <LinearProgress />
            )}
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setReleasenotesModalIsOpen(false)}
          >
            {t("general.Close")}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="Landing">
        <div className="bigEllipse">
          <svg width="810" height="410">
            <ellipse
              cx="550"
              cy="-100"
              rx="500"
              ry="400"
              strokeWidth="50"
              stroke="url(#bigEllipseGradient)"
              fill="none"
            />
            <defs>
              <linearGradient
                id="bigEllipseGradient"
                gradientTransform="rotate(30)"
              >
                <stop offset="5%" stopColor="#8770ff" />
                <stop offset="95%" stopColor="#629afd" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="smallEllipse">
          <svg width="510" height="410">
            <ellipse
              cx="605"
              cy="505"
              rx="650"
              ry="500"
              strokeWidth="5"
              stroke="url(#smallEllipseGradient)"
              fill="none"
            />
            <defs>
              <linearGradient
                id="smallEllipseGradient"
                gradientTransform="rotate(30)"
              >
                <stop offset="5%" stopColor="#f38363" />
                <stop offset="95%" stopColor="#ffc837" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <Typography
          variant="h4"
          style={{ color: "white", textAlign: "center", paddingBottom: "40px" }}
        >
          {t("landing.Welcome")} {currentUser}
        </Typography>

        <Grow
          in={!backDropIsOpen}
          timeout={{ enter: 1000 }}
          mountOnEnter
          unmountOnExit
        >
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {(userRoles.includes(10000) ||
              userRoles.includes(11000) ||
              userRoles.includes(12000)) &&
            mainServicesArray.includes("Receive") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/Receive"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <ReceiveIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.Receive")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}

            {(userRoles.includes(50000) || userRoles.includes(51000)) &&
            mainServicesArray.includes("Send") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/Send"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <SendIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.Send")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}

            {userRoles.includes(30000) &&
            mainServicesArray.includes("ProcurationManager") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/ProcurationManager"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <ProcIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.ProcurationManager")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}

            {userRoles.includes(40000) &&
            mainServicesArray.includes("FileUpload") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/FileUpload"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <FileUploadIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.FileUpload")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}

            {userRoles.includes(20000) &&
            mainServicesArray.includes("Report") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/Report"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <ReportIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.Report")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}

            {userRoles.includes(99000) &&
            mainServicesArray.includes("Admin") ? (
              <Grid item>
                <ButtonBase
                  style={{ display: "block" }}
                  to={"/Admin"}
                  component={NavLink}
                  className="ModuleButton"
                >
                  <div
                    style={{
                      width: "12rem",
                      height: "10rem",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.greyBox.main,
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <AdministrationIcon
                      style={{
                        fontSize: "8rem",
                        color: theme.palette.darkBackground.main,
                      }}
                    />
                  </div>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      color: "white",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {t("service.Admin")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ) : null}
          </Grid>
        </Grow>
      </div>

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {customerID === "admin" ? (
          <Grid item style={{ height: "calc(100vh - 450px)" }}>
            <PerfectScrollbar
              options={{
                suppressScrollX: true,
              }}
            >
              <Paper variant="outlined">
                <AdminChart />
              </Paper>
              <Typography
                variant="caption"
                display="block"
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingRight: "5px",
                  paddingTop: "5px",
                }}
              >
                *{t("landing.handlesExceptions")}
              </Typography>
            </PerfectScrollbar>
          </Grid>
        ) : null}

        {userRoles.includes(12000) &&
        mainServicesArray.includes("Receive") &&
        customerID !== "admin" ? (
          <Grid item>
            <Typography gutterBottom variant="h5" component="h2">
              {t("service.ReceivedMessages")}
            </Typography>
            <PerfectScrollbar
              className="DoughnutGrid"
              options={{
                suppressScrollX: true,
              }}
            >
              <ReceivedMessageChart
                processedToday={rmProcessedTodayCount}
                exceptionCount={exceptionCount.receivedMessage}
                rejectedCount={rmRejectedCount}
                excludedCount={rmExcludedCount}
                noAttachmentCount={rmNoAttachmentCount}
              />
            </PerfectScrollbar>
          </Grid>
        ) : null}

        {(userRoles.includes(10000) || userRoles.includes(11000)) &&
        mainServicesArray.includes("Receive") &&
        customerID !== "admin" ? (
          <Grid item>
            <Typography gutterBottom variant="h5" component="h2">
              {t("service.Receive")}
            </Typography>
            <PerfectScrollbar
              className="DoughnutGrid"
              options={{
                suppressScrollX: true,
              }}
            >
              <ReceiveChart
                processedToday={processedTodayCount.receive}
                actionCount={exceptionCount.receive_invoiceActions}
                deletedToday={deletedToday.receive}
                returnedToday={returnedTodayCount}
                exceptionCount={exceptionCount.receive_invoice}
              />
            </PerfectScrollbar>
          </Grid>
        ) : null}

        {(userRoles.includes(50000) || userRoles.includes(51000)) &&
        mainServicesArray.includes("Send") &&
        customerID !== "admin" ? (
          <Grid item>
            <Typography gutterBottom variant="h5" component="h2">
              {t("service.Send")}
            </Typography>
            <PerfectScrollbar
              className="DoughnutGrid"
              options={{
                suppressScrollX: true,
              }}
            >
              <SendChart
                processedToday={processedTodayCount.send}
                deletedToday={deletedToday.send}
                exceptionCount={exceptionCount.send_invoice}
                actionCount={exceptionCount.send_invoiceActions}
              />
            </PerfectScrollbar>
          </Grid>
        ) : null}
      </Grid>

      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: "20px",
        }}
      >
        <IconButton
          color="primary"
          onClick={() => setWhatsNewModalIsOpen(true)}
        >
          <InfoIcon style={{ marginRight: "15px" }} />
          {t("landing.WhatsNew")}
        </IconButton>
        <br />
        <IconButton
          color="primary"
          onClick={() => setReleasenotesModalIsOpen(true)}
        >
          <ReleaseNotesIcon style={{ marginRight: "15px" }} /> Release notes
        </IconButton>
      </div>
    </div>
  );
}
