import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Popper,
  CircularProgress,
  LinearProgress,
  Box,
  Typography,
  Grow,
  TextField,
  Autocomplete,
  InputAdornment,
  createFilterOptions,
} from "@mui/material";

//recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atomCustomerID,
  atomAllServices,
  atomMainServicesArray,
  atomDocumentConfiguration,
  atomBackDropIsOpen,
  atomUserRoles,
} from "../../Recoil";

//AWS
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";

//icons
import {
  Business as BusinessIcon,
  Square as SquareIcon,
} from "@mui/icons-material";

export const DividerWithText = ({ children }) => {
  return (
    <div className="DividerContainer">
      <div className="DividerContent">
        <SquareIcon
          color="primary"
          sx={{ fontSize: "0.6rem", paddingRight: "5px" }}
        />
        {children}
      </div>
      <div className="DividerBorder" />
    </div>
  );
};

export const LoadingSpinner = () => {
  const [comeIn, setComeIn] = useState(true);

  useEffect(() => {
    let interval = setInterval(() => {
      setComeIn(!comeIn);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [comeIn]);

  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 243.06 187.81"
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={233}
          y1={78.73}
          x2={224.76}
          y2={111.72}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f38363" />
          <stop offset={0.51} stopColor="#f38163" />
          <stop offset={0.7} stopColor="#f27a65" />
          <stop offset={0.83} stopColor="#f16f68" />
          <stop offset={0.94} stopColor="#ef5e6c" />
          <stop offset={1} stopColor="#ee506f" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={359.59}
          y1={279.79}
          x2={359.59}
          y2={323.59}
          gradientTransform="rotate(9.99 359.656 298.236)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f38363" />
          <stop offset={1} stopColor="#ee506f" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={745.46}
          y1={298.19}
          x2={386.45}
          y2={298.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#629afd" />
          <stop offset={0.54} stopColor="#6498fd" />
          <stop offset={0.73} stopColor="#6a91fd" />
          <stop offset={0.87} stopColor="#7486fe" />
          <stop offset={0.98} stopColor="#8375ff" />
          <stop offset={1} stopColor="#8770ff" />
        </linearGradient>
      </defs>

      <Grow in={comeIn} timeout={1800}>
        <circle cx={168.36} cy={93.91} r={32.35} fill="#08286a" />
      </Grow>
      <Grow in={comeIn} timeout={200}>
        <circle cx={11.96} cy={93.91} r={11.96} fill="#ee506f" />
      </Grow>
      <Grow in={comeIn} timeout={2600}>
        <circle cx={229.21} cy={93.91} r={13.85} fill="url(#prefix__a)" />
      </Grow>
      <Grow in={comeIn} timeout={1000}>
        <circle cx={60} cy={93.91} r={22.39} fill="url(#prefix__b)" />
      </Grow>

      <path
        d="M521.65 341.73a26.38 26.38 0 00-10.71-2 25.13 25.13 0 00-14.42 4.33 63.09 63.09 0 01-14.52 7.29 51.73 51.73 0 01-16.78 2.7 55.21 55.21 0 01-21.76-4.37 57.51 57.51 0 01-30.31-29.74 53.91 53.91 0 010-43.49 57.2 57.2 0 0112.32-17.72 59.32 59.32 0 0118-12 52.44 52.44 0 0110.72-3.35l1.05-.21a57.41 57.41 0 0110-.88 52.15 52.15 0 0116.59 2.71 61.05 61.05 0 0114.71 7.32 25 25 0 0014.4 4.45 25.63 25.63 0 0010.57-2.1 27.7 27.7 0 008.35-5.74l9.44-9.45a139.9 139.9 0 00-15.3-13.65 114.71 114.71 0 00-18.62-11.63 98 98 0 00-19.77-7.35 83.2 83.2 0 00-20.38-2.56 93.93 93.93 0 00-25.09 3.34 96.61 96.61 0 00-22.65 9.46 94.16 94.16 0 00-42.8 53.57 31.39 31.39 0 010 55.08 94.3 94.3 0 0042.8 53.56 96.19 96.19 0 0022.65 9.46 94.34 94.34 0 0025.09 3.34 83.21 83.21 0 0020.38-2.57 100 100 0 0019.78-7.34A115.06 115.06 0 00524 370.56a141 141 0 0015.33-13.63l-9.44-9.44a27.71 27.71 0 00-8.24-5.76z"
        transform="translate(-299.42 -204.29)"
        fill="url(#prefix__c)"
      />
    </svg>
  );
};

export const UploadProgress = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          style={{ height: "10px" }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export const CustomerSelector = () => {
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useRecoilState(atomCustomerID);
  const [allCustomers, setAllCustomers] = useState([]);
  const setUserRoles = useSetRecoilState(atomUserRoles);
  const setAllServices = useSetRecoilState(atomAllServices);
  const setMainServicesArray = useSetRecoilState(atomMainServicesArray);
  const setDocumentConfiguration = useSetRecoilState(atomDocumentConfiguration);
  const setBackDropIsOpen = useSetRecoilState(atomBackDropIsOpen);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    trim: true,
    stringify: (option) => `${option.id} ${option.name}`,
  });

  useEffect(() => {
    if (customerID !== "") {
      getServices(customerID);
    }
  }, [customerID]); // eslint-disable-line react-hooks/exhaustive-deps

  const getServices = async (localCustomerID) => {
    setBackDropIsOpen(true);

    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((authData) => {
        let localUserRoles = JSON.parse(
          authData.signInUserSession.idToken.payload["custom:Roles"]
        );
        let isAdmin = false;
        for (let key in localUserRoles) {
          if (key === "admin") {
            isAdmin = true;
          }
        }
        if (isAdmin) {
          setUserRoles(localUserRoles.admin);
        } else if (localUserRoles[localCustomerID] !== undefined) {
          setUserRoles(localUserRoles[localCustomerID]);
        } else {
          setUserRoles([]);
        }

        let groups =
          authData.signInUserSession.accessToken.payload["cognito:groups"];
        let sortedGroups = [];
        if (Array.isArray(groups)) {
          groups.sort();
          sortedGroups = groups;
        } else {
          sortedGroups = [groups];
        }

        GetAllCustomers(sortedGroups);
      })
      .catch((err) => {
        console.log(err);
      });

    try {
      setCustomerID(localCustomerID);
      const customerData = await API.graphql(
        graphqlOperation(queries.getCustomer, { id: localCustomerID })
      );
      setAllServices(customerData.data.getCustomer.services);

      let localMainServicesArray = [];
      customerData.data.getCustomer.services.forEach((service) =>
        localMainServicesArray.push(service.name)
      );
      setMainServicesArray(localMainServicesArray);
    } catch (err) {
      console.log(err);
      setBackDropIsOpen(false);
    }

    GetCustomerDocumentConfiguration(localCustomerID);

    setTimeout(() => {
      setBackDropIsOpen(false);
    }, 200);
  };

  const GetCustomerDocumentConfiguration = async (localCustomerID) => {
    try {
      const documentConfiguration = await API.graphql(
        graphqlOperation(queries.listFieldConfigurations, {
          id: localCustomerID,
        })
      );

      setDocumentConfiguration(
        documentConfiguration.data.listFieldConfigurations.items
      );
    } catch (err) {
      setBackDropIsOpen(false);
      console.log(err);
    }
  };

  const GetAllCustomers = async (sortedGroups) => {
    try {
      if (sortedGroups.includes("admin")) {
        let nextToken
        let resultArray = [];
        do {
          const result = await API.graphql(
            graphqlOperation(queries.listCustomers, {
              limit: 500,
              nextToken: nextToken,
            })
          );
          nextToken = result.data.listCustomers.nextToken;
          resultArray = resultArray.concat(result.data.listCustomers.items);
        } while (nextToken);
  
        const allCustomersSorted = resultArray.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setAllCustomers(allCustomersSorted);
      } else {
        let customerArray = [];
        for (const group of sortedGroups) {
          const localCustomer = await API.graphql(
            graphqlOperation(queries.getCustomer, {
              id: group,
            })
          );
          if(localCustomer.data.getCustomer !== null){
            customerArray.push(localCustomer.data.getCustomer);
          }
        }

        const sortedCustomers = customerArray.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setAllCustomers(sortedCustomers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ maxWidth: "fit-content", minWidth: "150px" }}
        placement="bottom-end"
      />
    );
  };

  if (allCustomers.length > 1) {
    return (
      <Autocomplete
        className="customerAutoComplete"
        options={allCustomers}
        value={customerID}
        filterOptions={filterOptions}
        closeText={t("general.Close")}
        clearText={t("general.Clear")}
        noOptionsText={t("general.table.NoRecord")}
        disableClearable
        openOnFocus
        PopperComponent={PopperMy}
        renderInput={(params) => {
          return (
            <TextField
              variant="standard"
              {...params}
              InputProps={{
                ...params.InputProps,
                sx: {
                  minWidth: "150px",
                  width: params.inputProps.value.toString().length * 9 + 60,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon
                      style={{
                        color: "white",
                        fontSize: "1.2em",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <span
              {...props}
              style={{ textTransform: "uppercase", fontSize: "0.9em" }}
            >
              {option.name}
            </span>
          );
        }}
        getOptionLabel={(option) => {
          if (typeof option === "object") return option.name;
          else
            return allCustomers.filter((customer) => customer.id === option)[0]
              .name;
        }}
        isOptionEqualToValue={(option, value) => option.id === value}
        autoHighlight
        onChange={(_, value) => {
          getServices(value.id);
        }}
      />
    );
  } else if (allCustomers.length === 1) {
    return (
      <Button
        startIcon={
          <BusinessIcon
            style={{
              width: "1.5em",
              height: "1em",
            }}
          />
        }
      >
        {customerID}
      </Button>
    );
  } else {
    return (
      <Button
        disabled={true}
        startIcon={
          <BusinessIcon
            style={{
              width: "1.5em",
              height: "1em",
            }}
          />
        }
      >
        <CircularProgress size={24} />
      </Button>
    );
  }
};
