import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Hidden,
  Drawer,
  IconButton,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

//pages
import NavBarMenu from "./NavBarMenu";
import NavBarSecondaryMenu from "./NavBarSecondaryMenu";

//components
import { Context } from "../../envVars";

//icons
import { Menu as MenuIcon } from "@mui/icons-material";

//images
import FourExchangeLogo from "../../images/4Exchange_logo.svg";

export default function Header() {
  const theme = useTheme();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      setDrawerIsOpen(false);
    }

    setDrawerIsOpen(open);
  };

  const logoPath = () => {
    switch (Context["unit"]) {
      case "ICreative":
        return FourExchangeLogo;
      case "Easy Systems":
        return require("../../images/Easy.png");
      default:
        return require("../../images/4CEE.png");
    }
  };

  return (
    <AppBar
      position="static"
      className="MainAppBar"
      style={{
        height: "87px",
      }}
    >
      <Toolbar>
        <Container maxWidth={false}>
          <Link to="/" style={{ float: "left", marginTop: "5px" }}>
            <img alt="ICreative Logo" src={logoPath()} className="logo" />
          </Link>
          <Hidden mdDown>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavBarMenu propsClass={"navDisplayFlex"} />
            </div>

            <div style={{ marginLeft: "15px" }}>
              <NavBarSecondaryMenu propsClass={"secondaryNavDisplayFlex"} />
            </div>
          </Hidden>
          <Hidden mdUp>
            <React.Fragment>
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                style={{ float: "right" }}
                size="large"
              >
                <MenuIcon fontSize="large" style={{ color: `white` }} />
              </IconButton>

              <Drawer
                anchor="right"
                open={drawerIsOpen}
                onClose={toggleDrawer(false)}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.darkBackground.main,
                    color: "white",
                  }}
                  className="MainDrawer"
                  onClick={toggleDrawer(true)}
                  onKeyDown={toggleDrawer(true)}
                >
                  <NavBarMenu />
                  <NavBarSecondaryMenu />
                </div>
              </Drawer>
            </React.Fragment>
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
