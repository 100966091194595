import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";

//i18n
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

//AWS
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";

import { RecoilRoot } from "recoil";

//css
import "./css/index.css";

//pages
import App from "./App";

//components
import { Context } from "./envVars";

awsconfig.oauth.domain = Context["ssoUrl"];
awsconfig.oauth.redirectSignIn = Context["appUrl"];
awsconfig.oauth.redirectSignOut = Context["appUrl"] + "logout";
Amplify.configure(awsconfig);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <I18nextProvider i18n={i18n}>
    <RecoilRoot>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </RecoilRoot>
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();