import React, { useEffect, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Snackbar, Slide, Backdrop, Alert } from "@mui/material";

//components
import ErrorBoundary from "./pages/Components/ErrorBoundary";

//recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atomCustomerID,
  atomMainServicesArray,
  atomSnackbarIsOpen,
  atomSnackbarSeverity,
  atomSnackbarMessage,
  atomUserRoles,
} from "./Recoil";

//css
import "react-perfect-scrollbar/dist/css/styles.css";

//components
import Header from "./pages/Components/Header";
import { LoadingSpinner } from "./pages/Components/StyleComponents";

//pages
import Landing from "./pages/Landing";
const Receive = lazy(() => import("./pages/ValidationService"));
const Report = lazy(() => import("./pages/Report"));
const ProcurationManager = lazy(() => import("./pages/ProcurationManager"));
const FileUpload = lazy(() => import("./pages/FileUpload"));
const Admin = lazy(() => import("./pages/Admin/AdminPage"));

export default function MainApp() {
  const { i18n } = useTranslation();

  const customerID = useRecoilValue(atomCustomerID);
  const mainServicesArray = useRecoilValue(atomMainServicesArray);
  const [snackbarIsOpen, setSnackbarIsOpen] =
    useRecoilState(atomSnackbarIsOpen);
  const snackbarSeverity = useRecoilValue(atomSnackbarSeverity);
  const snackbarMessage = useRecoilValue(atomSnackbarMessage);
  const userRoles = useRecoilValue(atomUserRoles);

  useEffect(() => {
    if (i18n.language.indexOf("-") !== -1) {
      i18n.language = i18n.language.split("-")[1].toLowerCase();
      if (i18n.language === "us") {
        i18n.language = "gb";
      }
    }
  }, [customerID]); // eslint-disable-line react-hooks/exhaustive-deps

  const slideTransition = (props) => {
    return <Slide {...props} direction="down" unmountOnExit />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setSnackbarIsOpen(false);
    }
    setSnackbarIsOpen(false);
  };

  const suspenseFallback = (
    <Backdrop className="BackDrop" open={true}>
      <div style={{ width: "10%" }}>
        <LoadingSpinner />
      </div>
    </Backdrop>
  );

  return (
    <div className="App">
      <Router>
        <Header mainServiceArray={mainServicesArray} />

        <ErrorBoundary>
          <div>
            <Snackbar
              open={snackbarIsOpen}
              onClose={handleClose}
              autoHideDuration={snackbarSeverity === "error" ? 10000 : 2500}
              TransitionComponent={slideTransition}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert severity={snackbarSeverity} onClose={handleClose}>
                {snackbarMessage}
              </Alert>
            </Snackbar>

            <Suspense fallback={suspenseFallback}>
              <Routes>
                {(userRoles.includes(10000) ||
                  userRoles.includes(11000) ||
                  userRoles.includes(12000)) &&
                mainServicesArray.includes("Receive") ? (
                  <Route exact path="/Receive/*" element={<Receive />} />
                ) : null}

                {(userRoles.includes(50000) || userRoles.includes(51000)) &&
                mainServicesArray.includes("Send") ? (
                  <Route exact path="/Send/*" element={<Receive />} />
                ) : null}

                {userRoles.includes(30000) &&
                mainServicesArray.includes("ProcurationManager") ? (
                  <Route
                    exact
                    path="/ProcurationManager"
                    element={<ProcurationManager />}
                  />
                ) : null}

                {userRoles.includes(20000) &&
                mainServicesArray.includes("Report") ? (
                  <Route path="/Report/*" element={<Report />} />
                ) : null}

                {userRoles.includes(40000) &&
                mainServicesArray.includes("FileUpload") ? (
                  <Route path="/FileUpload/*" element={<FileUpload />} />
                ) : null}

                {userRoles.includes(99000) ? (
                  <Route exact path="/Admin/*" element={<Admin />} />
                ) : null}

                <Route key="Landing" path="/" element={<Landing />} />
              </Routes>
            </Suspense>
          </div>
        </ErrorBoundary>
      </Router>
    </div>
  );
}
