import React from "react";

//icons
import {
  AddCircle,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  EditOutlined,
  FilterList,
  FirstPage,
  LastPage,
  Search,
  Remove,
  SaveAlt,
  ViewColumn,
  FlashOn,
  Assignment,
  LocalFireDepartment,
} from "@mui/icons-material";

export const TableLocal = (t) => {
  return {
    body: {
      emptyDataSourceMessage: t("general.table.NoRecord"),
      addTooltip: t("general.Add"),
      deleteTooltip: t("general.Delete"),
      editTooltip: t("general.Edit"),
      filterRow: {
        filterTooltip: t("general.table.Filter"),
      },
      editRow: {
        deleteText: t("general.table.DeleteConfirmation"),
        cancelTooltip: t("general.Cancel"),
        saveTooltip: t("general.Save"),
      },
    },
    header: {
      actions: t("general.table.Actions"),
    },
    pagination: {
      labelRows: "",
      labelDisplayedRows: "{from}-{to} of {count}",
      labelRowsPerPage: t("general.table.RowsPerPage"),
      firstAriaLabel: t("general.table.FirstPage"),
      firstTooltip: t("general.table.FirstPage"),
      previousAriaLabel: t("general.table.PrevPage"),
      previousTooltip: t("general.table.PrevPage"),
      nextAriaLabel: t("general.table.NextPage"),
      nextTooltip: t("general.table.NextPage"),
      lastAriaLabel: t("general.table.LastPage"),
      lastTooltip: t("general.table.LastPage"),
    },
    toolbar: {
      searchTooltip: t("general.Search"),
      searchPlaceholder: t("general.Search"),
    },
  };
};

export const TableIcons = {
    Start: () => <LocalFireDepartment style={{ color: "red" }} />,
    Check: () => <Check style={{ color: "green" }} />,
    Copy: () => <Assignment style={{ color: "grey" }} />,
    DetailPanel: ChevronRight,
    Delete: () => <DeleteOutline />,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    Search: Search,
    ThirdStateCheck: Remove,
    Add: () => <AddCircle color="primary" />,
    SortArrow: ArrowDownward,
    Clear: Clear,
    Edit: () => <EditOutlined />,
    ViewColumn: ViewColumn,
    ResetSearch: Clear,
    TestIcon: () => <FlashOn style={{ color: "#e8de25" }} />,
};
